<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <b-form-group
            id="input-group-guidance-type"
            label="Jenis Bimbingan:"
            label-for="input-guidance-type"
          >
            <treeselect
              v-model="form.guidance_type_id"
              :multiple="false"
              placeholder="Pilih Jenis Bimbingan"
              :options="guidance_types"
              @input="setMinimumQuantity"
            />
            <small class="text-danger">{{ error.guidance_type_id }}</small>
          </b-form-group>

          <label for="">Periode Konsultasi</label>
          <div class="row">
            <div class="col">
              <b-form-group id="input-group-start-date">
                <label for="input-start-date">Dari Tanggal: </label>
                <v-menu
                  v-model="menuDate1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-form-input
                      id="input-date"
                      v-model="form.start_date"
                      label="Tanggal Awal:"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></b-form-input>
                  </template>
                  <v-date-picker
                    v-model="form.start_date"
                    @input="menuDate1 = false"
                  ></v-date-picker>
                </v-menu>
                <small class="text-danger">{{ error.start_date }}</small>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-end-date">
                <label for="input-end-date">Sampai Tanggal: </label>
                <v-menu
                  v-model="menuDate2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-form-input
                      id="input-date"
                      v-model="form.end_date"
                      label="Tanggal Akhir:"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></b-form-input>
                  </template>
                  <v-date-picker
                    v-model="form.end_date"
                    @input="menuDate2 = false"
                  ></v-date-picker>
                </v-menu>
                <small class="text-danger">{{ error.end_date }}</small>
              </b-form-group>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <fieldset style="border: 1px solid #dee2e6; padding: 8px">
                <legend
                  style="
                    border: 1px solid #dee2e6;
                    padding: 9px;
                    margin-left: 13px;
                    font-size: initial;
                    width: initial;
                    background: white;
                  "
                >
                  Persyaratan
                </legend>
                <!-- Bimbingan Akademik -->
                <ol type="a" v-if="form.guidance_type_id == 1">
                  <li>
                    Mahasiswa sudah melaksanakan bimbingan dan mencatatkannya ke
                    dalam sistem minimal sebanyak 4x dalam 1 semester.
                  </li>
                  <li>
                    Dosen Pembimbing Akademik sudah melakukan konfirmasi
                    terhadap bimbingan mahasiswa minimal sebanyak 4x dalam 1
                    semester.
                  </li>
                </ol>

                <!-- Bimbingan Akademik -->
                <ol type="a" v-if="form.guidance_type_id == 2">
                  <li>
                    Mahasiswa sudah melaksanakan bimbingan dan mencatatkannya ke
                    dalam sistem minimal sebanyak 8x dalam 1 semester.
                  </li>
                  <li>
                    Dosen Pembimbing Akademik sudah melakukan konfirmasi
                    terhadap bimbingan mahasiswa minimal sebanyak 8x dalam 1
                    semester.
                  </li>
                </ol>

                <!-- Bimbingan PKM -->
                <ol type="a" v-if="form.guidance_type_id == 3">
                  <li>
                    Mahasiswa sudah melaksanakan bimbingan dan mencatatkannya ke
                    dalam sistem minimal sebanyak 8x dalam 1 semester.
                  </li>
                  <li>
                    Dosen Pembimbing Akademik sudah melakukan konfirmasi
                    terhadap bimbingan mahasiswa minimal sebanyak 8x dalam 1
                    semester.
                  </li>
                </ol>

                <!-- Bimbingan Skripsi -->
                <ol type="a" v-if="form.guidance_type_id == 4">
                  <li>
                    Mahasiswa sudah melaksanakan bimbingan dengan Dosen
                    Pembimbing 1 dan mencatatkannya ke dalam sistem minimal
                    sebanyak 8x dalam 1 semester.
                  </li>
                  <li>
                    Dosen Pembimbing 1 Skripsi sudah melakukan konfirmasi
                    terhadap bimbingan mahasiswa minimal sebanyak 8x dalam 1
                    semester.
                  </li>
                  <li>
                    Mahasiswa sudah melaksanakan bimbingan dengan Dosen
                    Pembimbing 2 dan mencatatkannya ke dalam sistem minimal
                    sebanyak 8x dalam 1 semester.
                  </li>
                  <li>
                    Dosen Pembimbing 2 Skripsi sudah melakukan konfirmasi
                    terhadap bimbingan mahasiswa minimal sebanyak 8x dalam 1
                    semester.
                  </li>
                </ol>

                <!-- Bimbingan Tesis -->
                <ol type="a" v-if="form.guidance_type_id == 5">
                  <li>
                    Mahasiswa sudah melaksanakan bimbingan dengan Dosen
                    Pembimbing 1 dan mencatatkannya ke dalam sistem minimal
                    sebanyak 8x dalam 1 semester.
                  </li>
                  <li>
                    Dosen Pembimbing 1 Tesis sudah melakukan konfirmasi
                    terhadap bimbingan mahasiswa minimal sebanyak 8x dalam 1
                    semester.
                  </li>
                  <li>
                    Mahasiswa sudah melaksanakan bimbingan dengan Dosen
                    Pembimbing 2 dan mencatatkannya ke dalam sistem minimal
                    sebanyak 8x dalam 1 semester.
                  </li>
                  <li>
                    Dosen Pembimbing 2 Tesis sudah melakukan konfirmasi
                    terhadap bimbingan mahasiswa minimal sebanyak 8x dalam 1
                    semester.
                  </li>
                </ol>

                <!-- Bimbingan MBKM -->
                <ol type="a" v-if="form.guidance_type_id == 6">
                  <li>
                    Mahasiswa sudah melaksanakan bimbingan dan mencatatkannya ke
                    dalam sistem minimal sebanyak 8x dalam 1 semester.
                  </li>
                  <li>
                    Dosen Pembimbing Akademik sudah melakukan konfirmasi
                    terhadap bimbingan mahasiswa minimal sebanyak 8x dalam 1
                    semester.
                  </li>
                </ol>

                <!-- Bimbingan Kemahasiswaan -->
                <ol type="a" v-if="form.guidance_type_id == 7">
                  <li>
                    Mahasiswa sudah melaksanakan bimbingan dan mencatatkannya ke
                    dalam sistem minimal sebanyak 8x dalam 1 semester.
                  </li>
                  <li>
                    Dosen Pembimbing Akademik sudah melakukan konfirmasi
                    terhadap bimbingan mahasiswa minimal sebanyak 8x dalam 1
                    semester.
                  </li>
                </ol>


                <h6><em>Bimbingan Anda</em></h6>
                <template
                  v-if="
                    form.guidance_type_id != 4 && form.guidance_type_id != 5 && form.guidance_type_id != 6 && form.guidance_type_id != 7
                  "
                >
                  <h6>
                    Jumlah bimbingan Anda saat ini :
                    <span v-if="guidanceQuantity.length > 0">{{
                      guidanceQuantity.length
                    }}</span>
                  </h6>
                </template>
                <template
                  v-if="
                    form.guidance_type_id == 4 || form.guidance_type_id == 5
                  "
                >
                  <span class="d-block">
                    Jumlah bimbingan Anda dengan Dosen Pembimbing 1 ({{
                      teacher_mentor_1_name
                    }}) saat ini :
                    <span v-if="mentor_1_quantity">{{
                      mentor_1_quantity
                    }}</span>
                  </span>
                  <span>
                    Jumlah bimbingan Anda dengan Dosen Pembimbing 2 ({{
                      teacher_mentor_2_name
                    }}) saat ini :
                    <span v-if="mentor_2_quantity">{{
                      mentor_2_quantity
                    }}</span>
                  </span>
                </template>
              </fieldset>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-12 py-0">
              <b-button @click="checkMyGuidance" variant="info" block>
                Cek Jumlah Bimbingan Saya
              </b-button>
            </div>
          </div>

          <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button
              type="submit"
              variant="primary"
              :disabled="qualify ? false : true"
              >Simpan</b-button
            >
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/print-request')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  data() {
    return {
      currentUser: getUser(),
      error: {
        guidance_type_id: "",
        student_id: "",
        verified_by: "",
        start_date: "",
        end_date: "",
      },
      menuDate1: false,
      menuDate2: false,
      qualify: false,
      minimumQuantity: "",
      guidanceQuantity: [],
      guidance_types: [],
      teacher_mentor_1:
        getUser().major_id == 1 || getUser().major_id == 3
          ? getUser().essay_mentor_1
          : getUser().thesis_mentor_1,
      teacher_mentor_1_name:
        getUser().major_id == 1 || getUser().major_id == 3
          ? getUser().essay_mentor_1_name
          : getUser().thesis_mentor_1_name,
      teacher_mentor_2:
        getUser().major_id == 1 || getUser().major_id == 3
          ? getUser().essay_mentor_2
          : getUser().thesis_mentor_2,
      teacher_mentor_2_name:
        getUser().major_id == 1 || getUser().major_id == 3
          ? getUser().essay_mentor_2_name
          : getUser().thesis_mentor_2_name,
      mentor_1_quantity: "",
      mentor_2_quantity: "",
    };
  },
  methods: {
    async getGuidanceTypeOption() {
      let response = await module.setTreeSelect(
        "api/guidance-types?page_size=1000"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.guidance_types = response.data;
        this.guidance_types.unshift({
          label: "Pilih Jenis Bimbingan",
          id: 0,
          isDisabled: true,
        });
      }
    },
    async getGuidanceQuantity() {
      this.qualify = false;
      this.minimumQuantityValidation();

      let response = "";
      if (
        this.form.guidance_type_id == 1 ||
        this.form.guidance_type_id == 2 ||
        this.form.guidance_type_id == 3
      ) {
        response = await module.paginate(
          `api/student-consultations/check-quantity/${this.currentUser.id}`,
          `?guidance_type_id=${this.form.guidance_type_id}&start_date=${this.form.start_date}&end_date=${this.form.end_date}`
        );
      }

      this.guidanceQuantity = response.data;
      if (this.guidanceQuantity.length >= this.minimumQuantity) {
        this.qualify = true;
        Swal.fire({
          title: "Memenuhi Syarat",
          text: "Jumlah bimbingan terpenuhi",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        this.qualify = false;
        Swal.fire({
          title: "Belum Memenuhi Syarat",
          text: "Jumlah bimbingan belum terpenuhi",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    async getEssayGuidanceQuantity() {
      SwalLoading.fire()
      this.qualify = false;
      let qualify = false

      let filterParams = `guidance_type_id=${this.form.guidance_type_id}&start_date=${this.form.start_date}&end_date=${this.form.end_date}`;
      let teacher_id = [];
      if (this.currentUser.major_id == 1 || this.currentUser.major_id == 3) {
        teacher_id = [
          this.currentUser.essay_mentor_1,
          this.currentUser.essay_mentor_2,
        ];
      } else if (this.currentUser.major_id == 2) {
        teacher_id = [
          this.currentUser.thesis_mentor_1,
          this.currentUser.thesis_mentor_2,
        ];
      }

      filterParams += `&teacher_id=${teacher_id.toString()}`;
      let response = await module.get(
        `api/student-consultations/check-quantity/${this.currentUser.id}`,
        `?${filterParams}`
      );
      // If Data Not Found
      if (response != null) {
        console.log("resp", response);
        for (let a = 0; a < response.length; a++) {
          if (response[a].teacher_1_id == this.teacher_mentor_1) {
            this.mentor_1_quantity = response[a].total;
          }
          if (response[a].teacher_1_id == this.teacher_mentor_2) {
            this.mentor_2_quantity = response[a].total;
          }
        }
      }

      if(this.mentor_1_quantity >= 8 || this.mentor_2_quantity >=8 ){
        Swal.fire({
          title: "Memenuhi Syarat",
          text: "Jumlah bimbingan terpenuhi",
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.qualify = true
      }else{
        Swal.fire({
          title: "Belum Memenuhi Syarat",
          text: "Jumlah bimbingan belum terpenuhi",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.qualify = false
      }

      // SwalLoading.close()
    },
    setMinimumQuantity(evt) {
      if (typeof evt == "undefined") {
        this.minimumQuantity = "";
        // this.getGuidanceQuantity();
        this.qualify = false;
      }

      this.minimumQuantityValidation();
    },
    minimumQuantityValidation() {
      if (this.form.guidance_type_id == 1) {
        this.minimumQuantity = 4;
      }
      if (this.form.guidance_type_id == 2) {
        this.minimumQuantity = 8;
      }
      if (this.form.guidance_type_id == 3) {
        this.minimumQuantity = 8;
      }
    },
    checkMyGuidance() {
      if (
        this.form.guidance_type_id == 1 ||
        this.form.guidance_type_id == 2 ||
        this.form.guidance_type_id == 3 ||
        this.form.guidance_type_id == 6 ||
        this.form.guidance_type_id == 7
      ) {
        this.getGuidanceQuantity();
      } else if (
        this.form.guidance_type_id == 4 ||
        this.form.guidance_type_id == 5
      ) {
        this.getEssayGuidanceQuantity();
      }
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$router.push("/print-request/list");
      }
    },
  },
  mounted() {
    this.getGuidanceTypeOption();
  },
};
</script>